<template>
    <VTemplate>
        <router-view></router-view>

        <Indicadores v-if="$route.name == 'Principal'" />
    </VTemplate>
</template>

<script>
import VTemplate from '@/components/Template'
import Indicadores from './frmIndicadores.vue'

export default {
    name: 'Principal',
    components: {
        VTemplate,
        Indicadores
    },
}
</script>